import styles from "./index.module.scss";
import { useTranslation } from "next-i18next";
import React from "react";
import { useState, useEffect } from "react";
import Image from "next/image";
import upIcon from "@images/ic_drop@2x.png";
import DownIcon from "@images/ic_drop_down@2x.png";
import { handleLogin } from "../../../utils/sys";
// import handler from '../../../pages/api/hello';
import { useRouter } from "next/router";

interface contentsType {
    id: number;
    question: string;
    answer: string;
    isShow: boolean;
}
const Index = () => {
    const router = useRouter();
    const handleT = () => {
        // window.location.href = "https://adsmeta-website-node.zhizh.com/portal/"
        // window.location.href = process.env.NEXT_PUBLIC_BASE_URL! + '/portal'
        window.location.href = process.env.NEXT_PUBLIC_CAOZUOTAI_URL!;
    };
    const { t, i18n } = useTranslation("home");
    const [showViewMore, setShowViewMore] =  useState(false)
    const [datas, setDatas] = useState<contentsType[]>([
        {
            question: "faqs-title-1",
            id: 1,
            answer: "faqs-content-1",
            isShow: true,
        },
        {
            question: "faqs-title-2",
            id: 2,
            answer: "faqs-content-2",
            isShow: false,
        },
        {
            question: "faqs-title-3",
            id: 3,
            answer: "faqs-content-3",
            isShow: false,
        },
        {
            question: "faqs-title-4",
            id: 4,
            answer: "faqs-content-4",
            isShow: false,
        },
    ]);
    const viewMore = ()=>{
        setDatas([...datas,
            {
                question: "faqs-title-5",
                id: 5,
                answer: "faqs-content-5",
                isShow: false,
            },
            {
                question: "faqs-title-6",
                id: 6,
                answer: "faqs-content-6",
                isShow: false,
            },
            {
                question: "faqs-title-7",
                id: 7,
                answer: "faqs-content-7",
                isShow: false,
            },
            {
                question: "faqs-title-8",
                id: 8,
                answer: "faqs-content-8",
                isShow: false,
            }]);
        setShowViewMore(true);
    }
    const openAnswer = (index: any, e: any) => {
        datas[index].isShow = !datas[index].isShow;
        setDatas([...datas]);
    };
    return (
        <div className={`${styles.faqs} animate-box`}>
            <div className={styles.faqsContainer}>
                <h2 className={styles.title}>{t("faqs")}</h2>

                {datas.map((item, index) => (
                    <div className={`${styles.item} item1`} key={item.id}>
                        <div
                            className={styles.question}
                            onClick={(e) => openAnswer(index, e)}
                        >
                            <div className={styles.question1}>
                                {t(`${item.question}`)}
                            </div>

                            {/* <span className={styles.downArrow}></span> */}
                            {/* <div > */}
                                <Image
                                    lazyBoundary="10px"
                                    alt=""
                                    src={item.isShow ? upIcon : DownIcon}
                                    className={styles.down}
                                    width={12}
                                    height={12}
                                ></Image>
                            {/* </div> */}
                        </div>
                        <div
                            className={styles.answer}
                            style={{ display: item.isShow ? "block" : "none" }}
                        >
                            {t(`${item.answer}`)}
                        </div>
                    </div>
                ))}
            </div>
            {!showViewMore?  <div className="text-center">
                    <button className={styles.viewMore} onClick={viewMore}>
                    {t('View More')}
                    </button>
                </div>:null}
        </div>
    );
};

export default Index;
